import React from 'react';
import { motion } from 'framer-motion';
import cloudVariants from './TransientThoughtCloudVariants';

const UserName = ({
  name = '', fillColor, position, windowSize
}) => {
  if (!name) return null;

  const getFontSize = (currentWindowSize) => {
    const variants = {
      large: 'text-2xl',
      medium: 'text-xl',
      small: 'text-lg'
    };

    return variants[currentWindowSize];
  };

  const getRelativeUserNamePosition = (currentPosition) => {
    const variants = {
      br: 'absolute -bottom-4 -right-12',
      bl: 'absolute -bottom-4 -left-12',
      tr: 'absolute -top-4 -right-12',
      tl: 'absolute -top-4 -left-12'
    };

    return variants[currentPosition];
  };

  const className = getRelativeUserNamePosition(position);
  const fontSize = getFontSize(windowSize);

  return (
    <div
      style={{
        padding: '4px 12px 4px 4px',
        width: 'fit-content',
        borderRadius: '4px',
        border: '1px solid #333333',
        background: 'rgba(34, 34, 34, 0.8)',
        height: 'fit-content'
      }}
      className={`flex x-5 items-center ${className} mx-2`}>
      <div style={{ background: fillColor }} className={`text-white px-2 rounded-sm mr-2 ${fontSize}`}>{name[0].toUpperCase()}</div>
      <p className={`text-white ${fontSize}`}>{name}</p>
    </div>
  );
};

const getCloudSvg = (config, fillColor, windowSize) => {
  if (!windowSize || !config.id) return null;

  return cloudVariants[`${windowSize}-${config.id}`](fillColor);
};

const getRelativeTextPosition = (limit, windowSize) => {
  const variants = {
    'large-17': 'absolute top-36 w-200 left-40 text-7xl h-96',
    'large-27': 'absolute top-36 w-200 left-36 text-7xl h-96',
    'large-34': 'absolute top-36 w-200 left-36 text-5xl h-96',
    'large-44': 'absolute top-36 w-246 left-12 text-4xl h-96',

    'medium-17': 'absolute top-32 left-36 w-200 text-6xl h-64',
    'medium-27': 'absolute top-32 left-36 w-200 text-5xl h-64',
    'medium-34': 'absolute top-32 left-36 w-200 text-4xl h-64',
    'medium-44': 'absolute top-32 left-36 w-200 text-3xl h-64',

    'small-17': 'absolute top-16 left-32 w-156 text-5xl h-64',
    'small-27': 'absolute top-16 left-32 w-156 text-4xl h-64',
    'small-34': 'absolute top-16 left-32 w-156 text-3xl h-64',
    'small-44': 'absolute top-16 left-32 w-156 text-2xl h-64'
  };

  return variants[`${windowSize}-${limit}`];
};

const TransientThoughtCloud = ({
  config,
  disableBubble,
  initiateAnimation,
  fillColor,
  textColor,
  transientThoughtShowUsername,
  fontFamily,
  containerSize = 'large'
}) => (
  <motion.div
    animate={config.animate}
    initial={{ opacity: 0, x: 0, y: 0 }}
    style={{
      filter: `drop-shadow(4px 6px 1px ${fillColor})`
    }}
    transition={config.transition}
    onAnimationComplete={(definition) => {
      initiateAnimation();
      disableBubble(definition);
    }}
    className={config.className}>
    {getCloudSvg(config, fillColor, containerSize)}
    {transientThoughtShowUsername
      && <UserName
        position={config.id}
        fillColor={fillColor}
        name={config?.username}
        windowSize={containerSize}
      />
    }
    <div lang='en'
      style={{
        color: textColor,
        lineHeight: 1.5,
        wordWrap: 'anywhere',
        fontFamily
      }}
      className={`flex justify-center items-center text-center ${getRelativeTextPosition(config.limit, containerSize)}`}>
      {config.words}
    </div>
  </motion.div>
);

export default TransientThoughtCloud;
