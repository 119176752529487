import { type SAFonts } from '@atoms/FontPicker';
import { Oswald, Poppins, Merriweather, Ultra, Lobster, Philosopher } from 'next/font/google';

const oswald = Oswald({ subsets: ['latin'] });
const poppins = Poppins({
  subsets: ['latin'],
  weight: '400',
});
const merriweather = Merriweather({
  subsets: ['latin'],
  weight: '400',
});
const ultra = Ultra({
  subsets: ['latin'],
  weight: '400',
});
const lobster = Lobster({
  subsets: ['latin'],
  weight: '400',
});
const philosopher = Philosopher({
  subsets: ['latin'],
  weight: '400',
});

export type FontStyle = {
  fontFamily: string;
  fontStyle?: string;
  fontWeight?: number;
}

export default function useFonts() {
  const getFontStyle = (fontName: SAFonts) => {
    const fonts: Record<SAFonts, FontStyle> = {
      Oswald: oswald.style,
      Poppins: poppins.style,
      Merriweather: merriweather.style,
      Ultra: ultra.style,
      Lobster: lobster.style,
      Philosopher: philosopher.style,
      'Helvetica Neue': {
        fontFamily: 'Helvetica Neue',
        fontStyle: 'normal',
        fontWeight: 400
      },
      'Sans-Serif': {
        fontFamily: 'Sans-Serif',
        fontStyle: 'normal',
        fontWeight: 400
      },
      Flama: {
        fontFamily: 'Flama',
        fontStyle: 'normal',
        fontWeight: 400
      },
    };

    return fonts[fontName as keyof typeof fonts];
  };

  return {
    getFontStyle,
  };
}
