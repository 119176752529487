import { createEngagement } from '@services/youtube-platform.service';
import { character } from '@components/RunOfShow/defaultSelectionData';
export const handleEngagement = async ({ streamId, platformType, currentRosFeature, interactionId = null }) => {
  const startTime = Date.now();
  const sessionId = localStorage.getItem('sessionId');

  let payload = {
    interactionId,
    startTime,
    title: currentRosFeature.title,
    platformType,
    questionId: currentRosFeature.id,
    brandColours: currentRosFeature.color,
    settings: currentRosFeature.setting,
    sessionId
  };

  if (currentRosFeature.options) {
    payload = { ...payload, options: currentRosFeature.options };
  }

  if (currentRosFeature.type) {
    payload = { ...payload, type: currentRosFeature.type };
  }

  return await createEngagement(streamId, currentRosFeature.type, payload);
};

export const getOptionsName = (currentRosFeature, number) => {
  if (currentRosFeature.type !== 'pollWithOptions') {
    return number + 1;
  }

  if (currentRosFeature.setting.OptionsList === 'character') {
    return character(number);
  }

  return number + 1;
};

export const mapContinents = [
  {
    name: 'World', value: 'world', zoom: 1.5, coordinates: [29, 9.88]
  },
  {
    name: 'Asia', value: 'asia', zoom: 2.5, coordinates: [110.878, 40.854]
  },
  {
    name: 'Africa', value: 'africa', zoom: 2.8, coordinates: [24.459, -2.079]
  },
  {
    name: 'Australia', value: 'australia', zoom: 3.5, coordinates: [132.689, -24.196]
  },
  {
    name: 'Europe', value: 'europe', zoom: 3, coordinates: [20.087, 52.975]
  },
  {
    name: 'North America', value: 'north-america', zoom: 2.8, coordinates: [-106.203, 52.494]
  },
  {
    name: 'South America', value: 'south-america', zoom: 2.8, coordinates: [-58.123, -25.491]
  }
];
