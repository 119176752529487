const cloudConfig = {
  bottomLeft: {
    id: 'bl',
    animate: {
      opacity: 1, x: 50
    },
    fillColor: '#FABBFF',
    className: 'absolute bottom-8 left-0'
  },
  bottomRight: {
    id: 'br',
    animate: {
      opacity: 1, x: -50
    },
    fillColor: '#FABBFF',
    className: 'absolute bottom-8 right-0'
  },
  topLeft: {
    id: 'tl',
    d: 'M95.8312 7.45307C96.1571 5.96551 97.0932 2.7849 99.3016 1.62346C102.226 0.0855575 96.9976 1.62346 94.261 3.73136C92.6844 4.94578 91.5848 6.25724 90.9359 7.15084C75.9255 6.36839 54.6303 6.40014 37.9213 6.42506C35.1463 6.4292 32.4978 6.43315 30.0256 6.43315C17.6163 6.43315 10.611 7.1063 6.69257 7.79358C3.49552 8.35432 1.87695 11.0648 1.87695 14.3107V29.8648C1.87695 36.9093 7.79214 42.5075 14.8257 42.1165L26.1585 41.4866C44.3798 40.4737 81.3387 40.981 98.6522 41.2973C104.173 41.3981 109.123 37.8274 110.24 32.42C112.059 23.6135 113.513 11.2797 107.996 9.24243C105.83 8.44272 101.502 7.86689 95.8312 7.45307Z',
    animate: {
      opacity: 1, x: 50
    },
    fillColor: '#FFF620',
    className: 'absolute top-8 left-0'
  },
  topRight: {
    id: 'tr',
    animate: {
      opacity: 1, x: -50
    },
    fillColor: '#FABBFF',
    className: 'absolute top-8 right-0'
  }
};

const bubbleConfig = {
  centerRight: {
    id: 'cr',
    d: 'M106.213 21.806C105.943 23.7835 105.588 25.741 105.217 27.5378C104.197 32.477 99.6754 35.7385 94.6327 35.6464C78.8182 35.3575 45.0593 34.8941 28.4156 35.8193L18.064 36.3947C11.6394 36.7519 6.23633 31.6383 6.23633 25.2038V10.9963C6.23633 8.0315 7.71476 5.55566 10.635 5.04347C14.2142 4.41569 20.613 3.80083 31.9478 3.80083C34.206 3.80083 36.6252 3.79722 39.1599 3.79344C62.6169 3.75846 95.969 3.70872 103.167 6.36688C106.286 7.51845 106.966 12.2717 106.638 17.6342C107.424 18.2206 108.417 19.0808 109.358 20.2351C111.353 22.68 112.89 27.4145 111.411 24.7837C110.35 22.8948 107.651 22.1026 106.213 21.806Z',
    animate: {
      scale: 7, opacity: 1, x: -300, y: 60
    },
    fillColor: '#88FFFF',
    className: 'absolute top-44 right-10',
    ellipse: {
      rx: '1.52426', ry: '2.1038', transform: 'matrix(0.743223 0.669044 0.669044 -0.743223 3.5404 3.58349)', fill: '#88FFFF', stroke: 'black', strokeWidth: '0.5'
    }
  },
  centerLeft: {
    id: 'cl',
    d: 'M104.221 6.36682C109.26 8.22771 107.932 19.4937 106.27 27.5377C105.25 32.4769 100.729 35.7385 95.6859 35.6464C79.8714 35.3574 46.1124 34.8941 29.4687 35.8192L19.1171 36.3947C12.6925 36.7518 7.28947 31.6383 7.28947 25.2037V22.7122C6.09971 22.9106 2.79337 23.6427 1.58994 25.7836C0.111099 28.4145 1.64875 23.6799 3.64325 21.235C5.01666 19.5515 6.49928 18.4937 7.28947 17.9981V10.9963C7.28947 8.03144 8.7679 5.5556 11.6881 5.0434C15.2673 4.41563 21.6661 3.80077 33.001 3.80077C35.2591 3.80077 37.6783 3.79716 40.2131 3.79338C63.67 3.7584 97.0222 3.70866 104.221 6.36682Z',
    animate: {
      scale: 7, opacity: 1, x: 300, y: 60
    },
    fillColor: '#88FFFF',
    className: 'absolute top-44 left-10',
    ellipse: {
      cx: '110.46', cy: '3.58349', rx: '1.52426', ry: '2.1038', transform: 'rotate(138.007 110.46 3.58349)', fill: '#88FFFF', stroke: 'black', strokeWidth: '0.5'
    }
  },
  topRight: {
    id: 'tr',
    d: 'M95.8312 7.45307C96.1571 5.96551 97.0932 2.7849 99.3016 1.62346C102.226 0.0855575 96.9976 1.62346 94.261 3.73136C92.6844 4.94578 91.5848 6.25724 90.9359 7.15084C75.9255 6.36839 54.6303 6.40014 37.9213 6.42506C35.1463 6.4292 32.4978 6.43315 30.0256 6.43315C17.6163 6.43315 10.611 7.1063 6.69257 7.79358C3.49552 8.35432 1.87695 11.0648 1.87695 14.3107V29.8648C1.87695 36.9093 7.79214 42.5075 14.8257 42.1165L26.1585 41.4866C44.3798 40.4737 81.3387 40.981 98.6522 41.2973C104.173 41.3981 109.123 37.8274 110.24 32.42C112.059 23.6135 113.513 11.2797 107.996 9.24243C105.83 8.44272 101.502 7.86689 95.8312 7.45307Z',
    animate: {
      scale: 7, opacity: 1, x: -350, y: 170
    },
    fillColor: '#FFF620',
    className: 'absolute right-5',
    ellipse: {
      cx: '3.7813', cy: '43.6442', rx: '1.66874', ry: '2.30322', transform: 'rotate(138.007 3.7813 43.6442)', fill: '#FFF620', stroke: 'black', strokeWidth: '0.5'
    }
  },
  topLeft: {
    id: 'tl',
    d: 'M16.797 6.70931C16.3281 5.0904 15.3736 2.80816 13.5764 1.86303C10.6521 0.325121 15.8804 1.86302 18.617 3.97093C19.7439 4.83899 20.6272 5.75664 21.2763 6.53582C23.6229 6.47022 26.237 6.43316 29.1486 6.43316C31.6208 6.43316 34.2693 6.42921 37.0443 6.42507C62.7247 6.38678 99.2382 6.33232 107.119 9.24244C112.636 11.2797 111.182 23.6136 109.363 32.42C108.246 37.8274 103.296 41.3982 97.7753 41.2973C80.4617 40.981 43.5028 40.4737 25.2815 41.4866L13.9488 42.1165C6.91518 42.5075 1 36.9093 1 29.8649V14.3107C1 11.0648 2.61857 8.35433 5.81562 7.79359C8.18114 7.37868 11.6717 6.96893 16.797 6.70931Z',
    animate: {
      scale: 7, opacity: 1, x: 350, y: 170
    },
    fillColor: '#FFF620',
    className: 'absolute -top-5',
    ellipse: {
      rx: '1.66874', ry: '2.30322', transform: 'matrix(0.743223 0.669044 0.669044 -0.743223 109.096 42.3578)', fill: '#FFF620', stroke: 'black', strokeWidth: '0.5'
    }
  },
  bottomLeft: {
    id: 'bl',
    d: 'M102.528 7.23029C107.806 9.17943 106.415 20.9797 104.675 29.4051C103.606 34.5786 98.8702 37.9948 93.5884 37.8983C77.0239 37.5957 41.664 37.1104 24.231 38.0794L13.3885 38.6821C13.2762 38.6884 13.1641 38.693 13.0524 38.6961C12.4142 39.5172 11.4584 40.578 10.1693 41.5709C7.55109 43.5876 2.54895 45.059 5.34677 43.5876C7.4755 42.4681 8.36856 39.3878 8.67396 37.9786C4.21213 36.3303 1 32.0381 1 26.9605V12.0793C1 8.97385 2.54854 6.3806 5.60728 5.84412C9.35618 5.18657 16.0584 4.54255 27.9308 4.54255C30.2961 4.54255 32.83 4.53877 35.4849 4.53481C60.0543 4.49817 94.9881 4.44607 102.528 7.23029Z',
    animate: {
      scale: 7, x: 320, y: -100, opacity: 1
    },
    fillColor: '#FABBFF',
    className: 'absolute bottom-10 left-8',
    ellipse: null,
    path: 'M108.494 3.05169C108.961 3.57019 109.203 4.14726 109.254 4.62812C109.305 5.11039 109.166 5.41452 108.987 5.5757C108.808 5.73687 108.491 5.84353 108.017 5.74201C107.544 5.6408 106.995 5.3398 106.528 4.8213C106.062 4.3028 105.82 3.72572 105.769 3.24487C105.717 2.76259 105.857 2.45846 106.036 2.29728C106.215 2.13611 106.532 2.02945 107.006 2.13097C107.479 2.23219 108.028 2.53319 108.494 3.05169Z'
  },
  bottomRight: {
    id: 'br',
    d: 'M107.67 6.46079C112.949 8.40993 111.557 20.2102 109.817 28.6356C108.749 33.8091 104.013 37.2253 98.731 37.1288C97.0721 37.0985 95.2247 37.0664 93.2256 37.0341C93.6581 38.5886 94.574 40.8848 96.3487 41.8181C99.1465 43.2895 94.1444 41.8181 91.5262 39.8014C90.2568 38.8236 89.3106 37.7801 88.6726 36.9644C70.5038 36.7021 43.8002 36.508 29.3736 37.3099L18.5311 37.9126C11.8019 38.2867 6.14258 32.9307 6.14258 26.191V11.3098C6.14258 8.20435 7.69112 5.6111 10.7499 5.07462C14.4988 4.41707 21.201 3.77305 33.0734 3.77305C35.4386 3.77305 37.9726 3.76927 40.6275 3.76531C65.1969 3.72867 100.131 3.67657 107.67 6.46079Z',
    animate: {
      scale: 7, x: -340, y: -110, opacity: 1
    },
    fillColor: '#FABBFF',
    className: 'absolute bottom-8 right-0',
    ellipse: null,
    path: 'M2.88851 3.05169C2.42176 3.57019 2.17988 4.14726 2.12875 4.62811C2.07748 5.11039 2.21676 5.41452 2.39581 5.5757C2.57485 5.73687 2.8919 5.84353 3.36615 5.74201C3.839 5.6408 4.38757 5.3398 4.85432 4.8213C5.32107 4.3028 5.56294 3.72572 5.61407 3.24487C5.66535 2.76259 5.52606 2.45846 5.34702 2.29728C5.16797 2.13611 4.85092 2.02945 4.37667 2.13097C3.90382 2.23219 3.35526 2.53319 2.88851 3.05169Z'
  }
};

const getReadingTime = (count) => {
  if (count > 200) return 10;
  if (count > 150) return 7.5;
  if (count > 100) return 5;
  if (count > 50) return 2.5;
  return 2;
};

module.exports = {
  bubbleConfig, getReadingTime, cloudConfig
};
