import React from 'react';
import { motion } from 'framer-motion';

const TransientThoughtBubble = ({
  config, disableBubble, initiateAnimation, variants, fillColor, textColor, fontFamily
}) => {
  const getFontSize = ({ limit }) => {
    const fontVariants = {
      17: 'text-7xl',
      27: 'text-5xl',
      34: 'text-4xl',
      44: 'text-2xl'
    };

    return fontVariants[limit];
  };

  const getRelativeTextPosition = ({ id, limit }) => {
    const fontSize = getFontSize({ limit });

    const positionVariants = {
      tl: `absolute w-170 -top-22 -left-72 ${fontSize} h-56`,
      bl: `absolute w-170 -top-24 -left-72 ${fontSize} h-56`,
      cl: `absolute w-170 -top-22 -left-70 ${fontSize} h-56`,
      br: `absolute w-170 -top-24 -left-72 ${fontSize} h-56`,
      tr: `absolute w-170 -top-22 -left-72 ${fontSize} h-56`,
      cr: `absolute w-170 -top-22 -left-72 ${fontSize} h-56`
    };

    return positionVariants[id];
  };

  return (
    <div
      className={config.className} >
      <motion.svg
        animate={config.animate}
        initial={{ opacity: 0 }}
        transition={config.transition}
        onAnimationComplete={disableBubble}
        width={114} height={46} xmlns="http://www.w3.org/2000/svg" fill="none">
        <motion.path
          initial={{ pathLength: 0 }}
          custom={config.transition?.delay || 0}
          onAnimationComplete={initiateAnimation}
          animate={'visible'}
          variants={variants}
          fillRule="evenodd" clipRule="evenodd" stroke="black" strokeWidth={0.5} d={config.d}
          fill={fillColor}
        ></motion.path>
        {config.ellipse ? <motion.ellipse {...config.ellipse} fill={fillColor} ></motion.ellipse> : <motion.path d={config.path} fill={fillColor} stroke='black' strokeWidth='0.5' />}
      </motion.svg>
      <motion.div
        animate={{
          ...config.animate, scale: config.animate.scale === 7 ? 1 : 0.14
        }}
        initial={{ opacity: 0, scale: 0.14 }}
        transition={config.transition}
        lang='en' style={{
          color: textColor,
          lineHeight: 1.5,
          wordWrap: 'anywhere',
          fontFamily
        }} className={`flex justify-center items-center text-center  ${getRelativeTextPosition(config)}`}>
        {config.words.split(' ').length > 44 ? `${config.words.split(' ').slice(0, 40).join(' ')}...` : config.words}
      </motion.div>
    </div>
  );
};

export default TransientThoughtBubble;
