const cloudVariants = {
  'large-tl': (fillColor) => <svg width="1096" height="668" viewBox="0 0 1096 668" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_994)">
      <rect x="32" y="126" width="1032" height="416" rx="208" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 542H824C752.577 600.19 655.258 636 548 636C440.742 636 343.423 600.19 272 542Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 126H780C719.964 67.8104 638.159 32 548 32C457.841 32 376.036 67.8104 316 126Z" fill={fillColor} />
      <path d="M744 492C744 436.772 788.772 392 844 392C899.228 392 944 436.772 944 492C944 547.228 899.228 592 844 592C788.772 592 744 547.228 744 492Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M152 176C152 231.228 196.772 276 252 276C307.228 276 352 231.228 352 176C352 120.772 307.228 76 252 76C196.772 76 152 120.772 152 176ZM158 63C158 76.2548 168.745 87 182 87C195.255 87 206 76.2548 206 63C206 49.7452 195.255 39 182 39C168.745 39 158 49.7452 158 63ZM145 52C139.477 52 135 47.5228 135 42C135 36.4772 139.477 32 145 32C150.523 32 155 36.4772 155 42C155 47.5228 150.523 52 145 52Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_994" x="0" y="0" width="1096" height="668" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.937255 0 0 0 0 0.768627 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_994" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_994" result="shape" />
      </filter>
    </defs>
  </svg>,
  'large-tr': (fillColor) => <svg width="1096" height="668" viewBox="0 0 1096 668" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1075)">
      <rect x="32" y="126" width="1032" height="416" rx="208" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 542H824C752.577 600.19 655.258 636 548 636C440.742 636 343.423 600.19 272 542Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 126H780C719.964 67.8104 638.159 32 548 32C457.841 32 376.036 67.8104 316 126Z" fill={fillColor} />
      <path d="M352 492C352 436.772 307.228 392 252 392C196.772 392 152 436.772 152 492C152 547.228 196.772 592 252 592C307.228 592 352 547.228 352 492Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M944 176C944 231.228 899.228 276 844 276C788.772 276 744 231.228 744 176C744 120.772 788.772 76 844 76C899.228 76 944 120.772 944 176ZM938 63C938 76.2548 927.255 87 914 87C900.745 87 890 76.2548 890 63C890 49.7452 900.745 39 914 39C927.255 39 938 49.7452 938 63ZM951 52C956.523 52 961 47.5228 961 42C961 36.4772 956.523 32 951 32C945.477 32 941 36.4772 941 42C941 47.5228 945.477 52 951 52Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1075" x="0" y="0" width="1096" height="668" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.313726 0 0 0 0 0.643137 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1075" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1075" result="shape" />
      </filter>
    </defs>
  </svg>,
  'large-br': (fillColor) => <svg width="1096" height="668" viewBox="0 0 1096 668" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1076)">
      <rect x="32" y="126" width="1032" height="416" rx="208" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 542H780C719.964 600.19 638.159 636 548 636C457.841 636 376.036 600.19 316 542Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 126H824C752.577 67.8104 655.258 32 548 32C440.742 32 343.423 67.8104 272 126Z" fill={fillColor} />
      <path d="M352 176C352 120.772 307.228 76 252 76C196.772 76 152 120.772 152 176C152 231.228 196.772 276 252 276C307.228 276 352 231.228 352 176Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M944 492C944 436.772 899.228 392 844 392C788.772 392 744 436.772 744 492C744 547.228 788.772 592 844 592C899.228 592 944 547.228 944 492ZM938 605C938 591.745 927.255 581 914 581C900.745 581 890 591.745 890 605C890 618.255 900.745 629 914 629C927.255 629 938 618.255 938 605ZM951 616C956.523 616 961 620.477 961 626C961 631.523 956.523 636 951 636C945.477 636 941 631.523 941 626C941 620.477 945.477 616 951 616Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1076" x="0" y="0" width="1096" height="668" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.768627 0 0 0 0 0.266667 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1076" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1076" result="shape" />
      </filter>
    </defs>
  </svg>,
  'large-bl': (fillColor) => <svg width="1096" height="668" viewBox="0 0 1096 668" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_3082_2066)">
      <rect x="32" y="126" width="1032" height="416" rx="208" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 542H780C719.964 600.19 638.159 636 548 636C457.841 636 376.036 600.19 316 542Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 126H824C752.577 67.8104 655.258 32 548 32C440.742 32 343.423 67.8104 272 126Z" fill={fillColor} />
      <path d="M352 176C352 120.772 307.228 76 252 76C196.772 76 152 120.772 152 176C152 231.228 196.772 276 252 276C307.228 276 352 231.228 352 176Z" fill={fillColor} />
      <path d="M744 492C744 436.772 788.772 392 844 392C899.228 392 944 436.772 944 492C944 547.228 899.228 592 844 592C788.772 592 744 547.228 744 492Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M152 492C152 436.772 196.772 392 252 392C307.228 392 352 436.772 352 492C352 547.228 307.228 592 252 592C196.772 592 152 547.228 152 492ZM158 605C158 591.745 168.745 581 182 581C195.255 581 206 591.745 206 605C206 618.255 195.255 629 182 629C168.745 629 158 618.255 158 605ZM145 616C139.477 616 135 620.477 135 626C135 631.523 139.477 636 145 636C150.523 636 155 631.523 155 626C155 620.477 150.523 616 145 616Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_3082_2066" x="0" y="0" width="1096" height="668" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.584314 0 0 0 0 0.403922 0 0 0 0 0.976471 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3082_2066" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3082_2066" result="shape" />
      </filter>
    </defs>
  </svg>,
  'medium-tl': (fillColor) => <svg width="1096" height="520" viewBox="0 0 1096 520" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1093)">
      <rect x="32" y="126" width="1032" height="268" rx="134" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 394H824C752.577 452.19 655.258 488 548 488C440.742 488 343.423 452.19 272 394Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 126H780C719.964 67.8104 638.159 32 548 32C457.841 32 376.036 67.8104 316 126Z" fill={fillColor} />
      <path d="M744 344C744 288.772 788.772 244 844 244C899.228 244 944 288.772 944 344C944 399.228 899.228 444 844 444C788.772 444 744 399.228 744 344Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M152 176C152 231.228 196.772 276 252 276C307.228 276 352 231.228 352 176C352 120.772 307.228 76 252 76C196.772 76 152 120.772 152 176ZM158 63C158 76.2548 168.745 87 182 87C195.255 87 206 76.2548 206 63C206 49.7452 195.255 39 182 39C168.745 39 158 49.7452 158 63ZM145 52C139.477 52 135 47.5228 135 42C135 36.4772 139.477 32 145 32C150.523 32 155 36.4772 155 42C155 47.5228 150.523 52 145 52Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1093" x="0" y="0" width="1096" height="520" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.180392 0 0 0 0 0.815686 0 0 0 0 0.780392 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1093" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1093" result="shape" />
      </filter>
    </defs>
  </svg>,
  'medium-tr': (fillColor) => <svg width="1096" height="520" viewBox="0 0 1096 520" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1110)">
      <rect x="32" y="126" width="1032" height="268" rx="134" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 394H824C752.577 452.19 655.258 488 548 488C440.742 488 343.423 452.19 272 394Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 126H780C719.964 67.8104 638.159 32 548 32C457.841 32 376.036 67.8104 316 126Z" fill={fillColor} />
      <path d="M152 344C152 288.772 196.772 244 252 244C307.228 244 352 288.772 352 344C352 399.228 307.228 444 252 444C196.772 444 152 399.228 152 344Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M944 176C944 231.228 899.228 276 844 276C788.772 276 744 231.228 744 176C744 120.772 788.772 76 844 76C899.228 76 944 120.772 944 176ZM938 63C938 76.2548 927.255 87 914 87C900.745 87 890 76.2548 890 63C890 49.7452 900.745 39 914 39C927.255 39 938 49.7452 938 63ZM951 52C956.523 52 961 47.5228 961 42C961 36.4772 956.523 32 951 32C945.477 32 941 36.4772 941 42C941 47.5228 945.477 52 951 52Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1110" x="0" y="0" width="1096" height="520" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.313726 0 0 0 0 0.643137 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1110" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1110" result="shape" />
      </filter>
    </defs>
  </svg>,
  'medium-br': (fillColor) => <svg width="1096" height="520" viewBox="0 0 1096 520" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1156)">
      <rect x="32" y="126" width="1032" height="268" rx="134" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 394H780C719.964 452.19 638.159 488 548 488C457.841 488 376.036 452.19 316 394Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 126H824C752.577 67.8104 655.258 32 548 32C440.742 32 343.423 67.8104 272 126Z" fill={fillColor} />
      <path d="M152 176C152 231.228 196.772 276 252 276C307.228 276 352 231.228 352 176C352 120.772 307.228 76 252 76C196.772 76 152 120.772 152 176Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M844 244C899.228 244 944 288.772 944 344C944 399.228 899.228 444 844 444C788.772 444 744 399.228 744 344C744 288.772 788.772 244 844 244ZM938 457C938 443.745 927.255 433 914 433C900.745 433 890 443.745 890 457C890 470.255 900.745 481 914 481C927.255 481 938 470.255 938 457ZM961 478C961 472.477 956.523 468 951 468C945.477 468 941 472.477 941 478C941 483.523 945.477 488 951 488C956.523 488 961 483.523 961 478Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1156" x="0" y="0" width="1096" height="520" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values={`
        0 0 0 0 0
        0 0 0 0 0 
        0 0 0 0 0 
        0 0 0 127 0`} result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values={`
        0 0 0 0 0.956863 
        0 0 0 0 0.768627
        0 0 0 0 0.266667
        0 0 0 1 0`} />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1156" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1156" result="shape" />
      </filter>
    </defs>
  </svg>,
  'medium-bl': (fillColor) => <svg width="1096" height="520" viewBox="0 0 1096 520" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1127)">
      <rect x="32" y="126" width="1032" height="268" rx="134" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M316 394H780C719.964 452.19 638.159 488 548 488C457.841 488 376.036 452.19 316 394Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M272 126H824C752.577 67.8104 655.258 32 548 32C440.742 32 343.423 67.8104 272 126Z" fill={fillColor} />
      <path d="M744 176C744 231.228 788.772 276 844 276C899.228 276 944 231.228 944 176C944 120.772 899.228 76 844 76C788.772 76 744 120.772 744 176Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M252 244C196.772 244 152 288.772 152 344C152 399.228 196.772 444 252 444C307.228 444 352 399.228 352 344C352 288.772 307.228 244 252 244ZM158 457C158 443.745 168.745 433 182 433C195.255 433 206 443.745 206 457C206 470.255 195.255 481 182 481C168.745 481 158 470.255 158 457ZM135 478C135 472.477 139.477 468 145 468C150.523 468 155 472.477 155 478C155 483.523 150.523 488 145 488C139.477 488 135 483.523 135 478Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1127" x="0" y="0" width="1096" height="520" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="
        0 0 0 0 0.584314
        0 0 0 0 0.403922
        0 0 0 0 0.976471
        0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1127" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1127" result="shape" />
      </filter>
    </defs>
  </svg>,
  'small-br': (fillColor) => <svg width="904" height="396" viewBox="0 0 904 396" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1224)">
      <rect x="32" y="108" width="840" height="172" rx="86" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M220 270H684C623.964 328.19 542.159 364 452 364C361.841 364 280.036 328.19 220 270Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M176 118H728C656.577 59.8104 559.258 24 452 24C344.742 24 247.423 59.8104 176 118Z" fill={fillColor} />
      <path d="M96 168C96 223.228 140.772 268 196 268C251.228 268 296 223.228 296 168C296 112.772 251.228 68 196 68C140.772 68 96 112.772 96 168Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M708 120C763.228 120 808 164.772 808 220C808 275.228 763.228 320 708 320C652.772 320 608 275.228 608 220C608 164.772 652.772 120 708 120ZM802 333C802 319.745 791.255 309 778 309C764.745 309 754 319.745 754 333C754 346.255 764.745 357 778 357C791.255 357 802 346.255 802 333ZM825 354C825 348.477 820.523 344 815 344C809.477 344 805 348.477 805 354C805 359.523 809.477 364 815 364C820.523 364 825 359.523 825 354Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1224" x="0" y="-8" width="904" height="404" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.768627 0 0 0 0 0.266667 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1224" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1224" result="shape" />
      </filter>
    </defs>
  </svg>,
  'small-bl': (fillColor) => <svg width="904" height="396" viewBox="0 0 904 396" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1207)">
      <rect x="32" y="108" width="840" height="172" rx="86" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M220 270H684C623.964 328.19 542.159 364 452 364C361.841 364 280.036 328.19 220 270Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M176 118H728C656.577 59.8104 559.258 24 452 24C344.742 24 247.423 59.8104 176 118Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M196 120C140.772 120 96 164.772 96 220C96 275.228 140.772 320 196 320C251.228 320 296 275.228 296 220C296 164.772 251.228 120 196 120ZM102 333C102 319.745 112.745 309 126 309C139.255 309 150 319.745 150 333C150 346.255 139.255 357 126 357C112.745 357 102 346.255 102 333ZM79 354C79 348.477 83.4772 344 89 344C94.5228 344 99 348.477 99 354C99 359.523 94.5228 364 89 364C83.4772 364 79 359.523 79 354Z" fill={fillColor} />
      <path d="M608 168C608 223.228 652.772 268 708 268C763.228 268 808 223.228 808 168C808 112.772 763.228 68 708 68C652.772 68 608 112.772 608 168Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1207" x="0" y="-8" width="904" height="404" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.584314 0 0 0 0 0.403922 0 0 0 0 0.976471 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1207" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1207" result="shape" />
      </filter>
    </defs>
  </svg>,
  'small-tr': (fillColor) => <svg width="904" height="396" viewBox="0 0 904 396" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1190)">
      <rect x="32" y="116" width="840" height="172" rx="86" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M176 278H728C656.577 336.19 559.258 372 452 372C344.742 372 247.423 336.19 176 278Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M220 126H684C623.964 67.8104 542.159 32 452 32C361.841 32 280.036 67.8104 220 126Z" fill={fillColor} />
      <path d="M96 228C96 172.772 140.772 128 196 128C251.228 128 296 172.772 296 228C296 283.228 251.228 328 196 328C140.772 328 96 283.228 96 228Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M808 176C808 231.228 763.228 276 708 276C652.772 276 608 231.228 608 176C608 120.772 652.772 76 708 76C763.228 76 808 120.772 808 176ZM802 63C802 76.2548 791.255 87 778 87C764.745 87 754 76.2548 754 63C754 49.7452 764.745 39 778 39C791.255 39 802 49.7452 802 63ZM815 52C820.523 52 825 47.5228 825 42C825 36.4772 820.523 32 815 32C809.477 32 805 36.4772 805 42C805 47.5228 809.477 52 815 52Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1190" x="0" y="0" width="904" height="404" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.313726 0 0 0 0 0.643137 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1190" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1190" result="shape" />
      </filter>
    </defs>
  </svg>,
  'small-tl': (fillColor) => <svg width="904" height="396" viewBox="0 0 904 396" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8" filter="url(#filter0_d_2984_1173)">
      <rect x="32" y="116" width="840" height="172" rx="86" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M176 278H728C656.577 336.19 559.258 372 452 372C344.742 372 247.423 336.19 176 278Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M220 126H684C623.964 67.8104 542.159 32 452 32C361.841 32 280.036 67.8104 220 126Z" fill={fillColor} />
      <path d="M608 228C608 172.772 652.772 128 708 128C763.228 128 808 172.772 808 228C808 283.228 763.228 328 708 328C652.772 328 608 283.228 608 228Z" fill={fillColor} />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M96 176C96 231.228 140.772 276 196 276C251.228 276 296 231.228 296 176C296 120.772 251.228 76 196 76C140.772 76 96 120.772 96 176ZM102 63C102 76.2548 112.745 87 126 87C139.255 87 150 76.2548 150 63C150 49.7452 139.255 39 126 39C112.745 39 102 49.7452 102 63ZM89 52C83.4772 52 79 47.5228 79 42C79 36.4772 83.4772 32 89 32C94.5228 32 99 36.4772 99 42C99 47.5228 94.5228 52 89 52Z" fill={fillColor} />
    </g>
    <defs>
      <filter id="filter0_d_2984_1173" x="0" y="0" width="904" height="404" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        {/* <feGaussianBlur stdDeviation="16" /> */}
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.956863 0 0 0 0 0.768627 0 0 0 0 0.266667 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2984_1173" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2984_1173" result="shape" />
      </filter>
    </defs>
  </svg>
};

export default cloudVariants;
